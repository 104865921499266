import { Loading } from 'element-ui'

let loading = null

export default {
  show() {
    if(loading) {
      return
    }
    loading = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  },
  close() {
    loading && loading.close()
    loading = null
  }
}
