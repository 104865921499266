<template>
  <div class="page page-login">
    <div class="mask"></div>

    <div class="dlg dlg-login">
      <button class="btn btn-txt btn-close" @click="tapClose"></button>

      <head-bar :tabArr="['设置登录密码']" :needBack="true" />

      <div class="box-bd">
        <input class="inp inp-tel" placeholder="请输入手机号" v-tel v-model="tel" />

        <div><input class="inp inp-next inp-psw" type="password" v-pswd placeholder="输入密码" v-model="psw" /></div>
        <div><input class="inp inp-next inp-psw" type="password" v-pswd placeholder="确认密码" v-model="cfPsw" /></div>
      </div>

      <div class="box-btns">
        <button class="btn btn-login btn-complete" @click="tapRegi">完成注册</button>
        <button class="btn btn-txt btn-jump" @click="tapJump">跳过</button>
      </div>
    </div>
  </div>
</template>

<script>
import HeadBar from './components/HeadBar.vue';
import VcodeInp from './components/VcodeInp.vue';

import model from "./model"

export default {
  components: { VcodeInp, HeadBar },
  data() {
    return {tel:'', psw:'', cfPsw:''}
  },
  methods: {
    tapRegi() {

    },
    tapClose() {
      model.closeByCrx()
    }
  }
}
</script>

<style lang="less" scoped>
@import "css/common";

.box-bd {
  width: 320px;
  margin: auto;
}
.inp-next {
  margin-top:24px;
}
.btn-jump {
  margin-top:13px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
</style>