// 检测是否是手机号码
export function checkIsTel (phone) {
  if (phone) {
    let reg = /^(13[0-9]|14[579]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[89])\d{8}$/;
    if (reg.test(phone)) {
      return true;
    }
  }
  return false;
}

export function getCookie(name) {
var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
if(arr=document.cookie.match(reg))
return unescape(arr[2]);
else
return null;
}

// function getsec(str)
// {
// alert(str);
// var str1=str.substring(1,str.length)*1;
// var str2=str.substring(0,1);
// if (str2=="s")
// {
// return str1*1000;
// }
// else if (str2=="h")
// {
// return str1*60*60*1000;
// }
// else if (str2=="d")
// {
// return str1*24*60*60*1000;
// }
// }
// function setCookie(name,value,time)
// {
// var strsec = getsec(time);
// var exp = new Date();
// exp.setTime(exp.getTime() + strsec*1);
// document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
// }