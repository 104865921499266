import axios from 'axios'

import loading from '../loading'

let isHttps = (window.location.origin.indexOf('https:') > -1)
let proc
if(isHttps) {
  proc = 'https://'
} else {
  proc = 'http://'
}

let baseUrl 
let nenv = process.env.NODE_ENV
if(nenv == 'production') {
  if(window.location.host.indexOf('ddzs.shunmakeji') > -1) {
    console.log('生产环境')
    // baseUrl = proc + 'www.duoduozhishu.cn:18036/v1/'
    baseUrl = proc + 'ddzs.shunmakeji.com/v1/'
  } else {
    console.log('测试环境')
    baseUrl = proc + 'testplugin.shunmakeji.com/v1/'
  }
// } else if(nenv == 'test') {
} else { //dev
  console.log('开发环境环境')
  baseUrl = '/v1/' //用上代理
}

const getBaseParam = () => {
  return {
    // t: new Date().getTime()
  }
}

const api = axios.create({
  baseURL: baseUrl,
  timeout: 8000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials:true, //允许带上 cokies
  params: {
    // platform: 'pc'
  }
})
// axios.defaults.withCredentials=true

// function heckHasCH  (str) {
//   return /[\u4E00-\u9FA5]/.test(str)
// }
function checkHasEmoji (str) {
  return /[\uD800-\uDFFF]/.test(str)
}
// 添加请求拦截器
api.interceptors.request.use(function (config = {}) {
  // if (carry.checkHasCH(JSON.stringify(config.params || {}))) {
  //   return Promise.reject(new Error('拦截参数有中文'))
  // }
  if (checkHasEmoji(JSON.stringify(config.data || {}))) {
    return Promise.reject(new Error('拦截emoji表情'))
  }
  // console.log('请求配置', config)
  if (config.needIndicator) {
    loading.show()
  }

  config.params = Object.assign(config.params || {}, getBaseParam())
  return config
}, function (error) {
  return Promise.reject(error)
})

// 添加响应拦截器
api.interceptors.response.use(function (response) {
  response = response || {}
  // console.log('response', JSON.stringify(response) )
  if (response.config.needIndicator) {
    loading.close()
  }
  let data = response.data || {}
  return data
}, function (error) {
  loading.close()
  console.error('网络错误', error)
  return Promise.reject({
    msg: "网络超时",
    success:'999999'
  })
})

export default api
