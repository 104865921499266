<template>
  <div class="dlg dlg-login dlg-succ">  
    <button class="btn btn-txt btn-close" @click="tapClose"></button>

    <div class="bd">
      <img src="../imgs/Shape@2x.png" />
      <span>{{succStr}}</span>
    </div>

    <button class="btn btn-login btn-2login" @click="tap2Login">去登录</button>
  </div>
</template>

<script>
import model from "../model"

export default {
  props: {
    succStr:String
  },
  methods: {
    tapClose() {
      model.closeByCrx()
    },
    tap2Login() {
      this.$router.push({
        name:'login'
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "../css/common";
.dlg-succ {
  background: rgba(0, 0, 0, 0.6);
}
.bd {
  display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    img {
      margin-bottom:24px;
    }

}
.btn-2login {
  margin-top:88px;
}
</style>