<template>
  <div class="box-hd">
        <div class="hd">
          <div v-show="needBack" class="btn btn-txt btn-back" @click="tapBackLogin">返回登录</div>
          <div></div>
        </div>

        <div class="tabs">
          <span :class="{ 'btn btn-txt btn-tab': true, 'btn-tab-active': tabsId == idx }" 
            @click="tapTab(idx)" v-for="(item, idx) in tabArr" :key="idx" >
            {{item}}
          </span>

        </div>
      </div>
</template>

<script>
export default {
  props: {
    tabArr:Array,
    needBack:Boolean
  },
  data() {
    return {
      tabsId:0
    }
  },
  methods: {
    tapTab(id) {
      this.tabsId = id;
      this.$emit('switchTab', id);
    },
    tapBackLogin() {
      this.$router.push({
        name:'login'
      })
    },
  }
  
}
</script>

<style lang="less" scoped>
.box-hd {
  height: 103px;
  margin-bottom: 50 - 10px;
  
  background: rgba(0, 0, 0, 0.04);
}
.hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 0px;
  .btn-txt {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }
}
.tabs {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  .btn-tab {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.45);
    position: relative;
    height: 28px + 11;
    margin-bottom: 11px;
  }
  .btn-tab-active {
    color: #2f54eb;
    &::after {
      position: absolute;
      width: 24px;
      height: 4px;
      background-color: #2f54eb;
      border-radius: 2px;
      content: "";
      margin: auto;
      left: 0;
      right: 0;
      bottom: 3px;
    }
  }
}
.btn-back {
  position: relative;
  padding-left: 24PX;
    height: 20px;
    line-height: 20px;
  &::after {
    content: "";
    position: absolute;
    margin: auto;
    top:0;bottom: 0;
    left:0;
    width:18px;
    height: 18px;
    background-size: 100%;
    background-image: url("../imgs/left@2x.png");
  }
}


// .btn-close {
//   width:18px;
//   height: 18px;
//   background-size: 100%;
//   background-image: url("../imgs/close@2x.png");
// }
</style>