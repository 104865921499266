import api from './api'

import md5 from 'md5'


import { getCookie } from "@/utils.js";

const tag = 'user/'


let jsessionId = ''



function loginOrRegister(data,query, _function) {
  query = query || {}
  let params = Object.assign({_function}, query) 
  return new Promise((resolve, reject) => {
    api({
      method: 'post',
      url: tag + 'loginOrRegister',
      data,
      params,
      needIndicator: true,
    }).then(function (res) {
      console.log('login rcv', JSON.stringify(res))
      if(res && res.obj && res.obj.jsessionId) {
        jsessionId = res.obj.jsessionId
      }
      resolve(res)
    }).catch(function (error) {
      resolve(error)
    })
  })
}
export default {
  getJsessionId() {
    return jsessionId
  },

  login(data,_func,query) {
    return loginOrRegister(data, query, _func)
  },

  register(data, query) {
    data = Object.assign(data, {
      apkChannel:'1',
      apkId:'1',
      apkVersionCode:'1',
      apkVersionName:'1',
      browserOs:'Chrome',
      browserOsVersion:'92.0.4515.159',
      createIp:'1',
      createTime:new Date().getTime(),
      phoneId:'1', //设备唯一标识
      level:0
    })
    return loginOrRegister(data, query, 'register')
  },

  // reset register loginByCode
  sendSms(tel, _function) {
    return new Promise((resolve, reject) => {
      api({
        method: 'post',
        url: tag + 'sendSms',
        data:{tel, key:md5(tel)},
        params:{_function},
        needIndicator: true,
      }).then(function (res) {
        resolve(res)
      }).catch(function (error) {
        resolve(error)
      })
    })
  },

  checkSms(tel, code) {
    return new Promise((resolve, reject) => {
      api({
        method: 'post',
        url: tag + 'checkSms',
        // data,
        params: {tel, code},
        needIndicator: true,
      }).then((res) => {
        resolve(res)
      }).catch((error) => {
        resolve(error)
      })
    })
  },

  reset(data, params) {
    return new Promise((resolve, reject) => {
      api({
        method: 'post',
        url: tag + 'reset',
        data,
        params,
        needIndicator: true,
      }).then((res) => {
        resolve(res)
      }).catch((error) => {
        resolve(error)
      })
    })
  },

  getUserById(uid) {
    if(!uid) {
      return Promise.resolve({})
    }
    return new Promise((resolve, reject) => {
      api({
        method: 'get',
        url:tag + 'getUserById', 
        params: {
          jSessionId:jsessionId
        },
        needIndicator: true,
        headers: {}
      }).then((res) => {
        console.log('get user info rcv', JSON.stringify(res))
        if(res.success != '1') {
        }
        resolve(res.obj || {})
      }).catch(err => {
        console.log('eee ', err)
        resolve({})
      })
    })
  }

}