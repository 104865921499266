<template>
  <div class="page page-login">
    <div class="mask"></div>

    <div class="dlg dlg-login">
      <button class="btn btn-txt btn-close" @click="tapClose"></button>

      <head-bar :tabArr="['忘记密码']" :needBack="true" />

      <div class="box-bd">
        <input class="inp inp-tel" placeholder="请输入手机号" v-tel v-model="tel" />
        <vcode-inp tag="reset" @vcodeChange="vcodeChange" />

        <div><input class="inp inp-next inp-psw" type="password" v-pswd placeholder="输入密码" v-model="psw" /></div>
        <div><input class="inp inp-next inp-psw" type="password" v-pswd placeholder="确认密码" v-model="cfPsw" /></div>

        <div class="tips">{{netMsg}}</div>
      </div>

      <div class="box-btns">
        <button class="btn btn-login" @click="tapReset">完成</button>
      </div>
    </div>

    <op-succ-dlg succStr="设置成功" v-show="opSucc" />
  </div>
</template>

<script>
import HeadBar from './components/HeadBar.vue';
import OpSuccDlg from './components/OpSuccDlg.vue';
import VcodeInp from './components/VcodeInp.vue';

import { checkIsTel } from "@/utils.js";
import model from "./model"

import loginApi from "@/net/loginApi"

import md5 from 'md5'

export default {
  components: { VcodeInp, HeadBar, OpSuccDlg },
  data() {
    return {tel:'', psw:'', cfPsw:'', vcode:'', netMsg:'', opSucc:false}
  },
  methods: {
    getVcode() {
      if (!checkIsTel(this.tel)) {
        this.netMsg = "请正确填写手机号"
        return '';
      }
      return this.tel
    },
    onVcodeRcv(data) {
      if(data.success != '1') {
        this.netMsg = data.msg
      } else {
        this.netMsg = ''
      }
    },
    vcodeChange(newVal) {
      this.vcode = newVal
      console.log('ccc', this.vcode)
    },

    tapClose() {
      model.closeByCrx()
    },
    async tapReset() {
      let msg = model.checkFrom({
        tel:this.tel,
        vcode:this.vcode,
        psw:  this.psw,
        cfPsw:this.cfPsw
      })
      if(msg) {
        this.netMsg = msg
        return
      }

      let data = await loginApi.checkSms(this.tel, this.vcode)
      if(data.success != '1') {
        this.netMsg = data.msg
        return
      }
      
      loginApi.reset({phone:this.tel, password: md5(this.psw) }, {code:this.vcode}).then((data) => {
        if(data.success != '1') {
          this.netMsg = data.msg
        } else {
          this.netMsg = ''
          this.opSucc = true
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "css/common";

.box-bd {
  width: 320px;
  margin: auto;
}
.inp-next {
  margin-top:16px;
}
</style>