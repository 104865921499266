<template>
  <div class="box-vcode">
          <input class="inp inp-next inp-vcode" type="text" oninput="value=value.replace(/[^\d]/g,'')" 
          maxlength="4" placeholder="验证码" v-model="vcode" />
          <button :class="{'btn btn-vcode':true, 'btn-vcode-running': countdown > -1}" @click="tapGetVcode">{{btnStr}}</button>
        </div>  
</template>

<script>
import loginApi from "@/net/loginApi"

export default {
  props: {
    tag:String
  },
  data() {
    return {
      vcode:'', timer:null,
      btnStr: '获取验证码',
      countdown:-1
    }
  },
  watch: {
    vcode(val, oldVal) {
      // console.log('val, oldVal', val, oldVal)
      this.$emit('vcodeChange', val);
    }
  },
  methods: {
    tapGetVcode() {
      if(this.timer) {return }
       
      // this.$parent.tel
      let tel = this.$parent.getVcode()
      if(!tel) {return}

      this.countdown = 60
      this.btnStr = this.countdown + 's'
      this.timer = setInterval(() => {
        this.countdown--
        if(this.countdown == -1) {
          this.btnStr = '获取验证码'
          clearInterval(this.timer)
          this.timer = null
          return
        }
        this.btnStr = this.countdown + 's'
      }, 1000);

      // 
      loginApi.sendSms(tel, this.tag).then((data) => {
        this.$parent.onVcodeRcv(data)
      }) 
    }
  }
}
</script>

<style lang="less" scoped>
@import "../css/common";

  .box-vcode {
    position: relative;
    width: 100%;
  }
  .btn-vcode {
    width: 102px;
    height: 24px;
    line-height: 21px;
    background: #2f54eb;
    border-radius: 2px;
    border: 1px solid #2f54eb;
    color: #ffffff;
    font-size: 14px;

    position: absolute;
    margin: auto;
    // top:0;
    bottom: 14px;
    right: 0;
    &-running {
      opacity: 0.2;
    }
  }
</style>