
import { checkIsTel } from "@/utils.js";
import loginApi from "../../net/loginApi";

let isFromCrx = false

export default {
  checkFrom({account, tel, vcode, psw, cfPsw}) {
    // console.log(tel, vcode, psw, cfPsw)
    if (account != null && !checkIsTel(account)) {
      return "请正确填写账号"
    }

    if (tel != null && !checkIsTel(tel)) {
      return "请正确填写手机号"
    }

    if(vcode != null && !vcode) {
      return "请输入验证码"
    }

    if(psw != null) {
      if(!psw) {
        return "输入登录密码"
      } else if(psw.length < 6) {
        return "请输入至少6位密码"
      }
    }
    if(cfPsw != null && !cfPsw) {
      return "确认登录密码"
    }
    if(psw != null && cfPsw != null &&
      psw != cfPsw) {
      return '前后密码输入不一致'
    }

    return ''
  },

  // 
  initFrom(query) {
    if(query.from == 'crx') {
      isFromCrx = true
    }
  },
  fromCrx() {
    return isFromCrx
  },
  closeByCrx() {
    console.log('isFromCrx', isFromCrx)
    if(isFromCrx) {
      let data = {from:'sm_web', tag:'close'}
      window.parent.postMessage(JSON.stringify(data), '*');
      return true
    }
    return false
  },
  async login2Crx(uid) {
    let userinfo = await loginApi.getUserById(uid)
    userinfo.jsessionId = userinfo.jsessionId || loginApi.getJsessionId()
    let data = {
      from:'sm_web', 
      tag:'login_succ', 
      // uid,
      userinfo
    }
    let dataStr = JSON.stringify(data)
    console.log('send to crx', userinfo.jsessionId)
    window.parent.postMessage(dataStr, '*');
  }


}