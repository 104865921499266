<template>
  <div class="page page-login">
    <div class="mask"></div>

    <div class="dlg dlg-login">
      <button id="smLoginCloseBtn" class="btn btn-txt btn-close" @click="tapClose"></button>

      <head-bar :tabArr="['密码登录', '验证码登录']" @switchTab="tapTab" />

      <div class="box-bd" v-show="tabsId == 0">
        <!-- autocomplete="on" 不起作用 -->
        <input class="inp inp-account" placeholder="账号" v-tel v-model="account" />

        <div class="box-psw">
          <input class="inp inp-next inp-psw" :type="pwdSeen ? 'text' : 'password'" v-pswd placeholder="请输入密码" v-model="password" />
          <button
            :class="{ 'btn btn-txt btn-seen': true, 'btn-seen-open': pwdSeen }"
            @click="pwdSeen = !pwdSeen"
          ></button>
        </div>

        <div class="tips">{{netMsg0}}</div>

        <div class="misspsw">
          <el-checkbox v-model="pswSave">记住我</el-checkbox>
          <button class="btn btn-txt" @click="tapMissPsw">忘记密码？</button>
        </div>

      </div>

      <div class="box-bd" v-show="tabsId == 1">
        <input class="inp inp-next inp-tel" placeholder="请输入手机号" v-tel v-model="tel" />
        <vcode-inp tag="loginByCode" @vcodeChange="vcodeChange" />
        <div class="tips">{{netMsg1}}</div>
      </div>

      <div class="box-btns">
        <button class="btn btn-login" @click="tapLogin">登 录</button>
        <button class="btn btn-txt btn-regi" @click="tapRegi">注册账号</button>
      </div>
    </div>
  </div>
</template>

<script>
// import loading from "@/loading";
import { checkIsTel } from "@/utils.js";
import VcodeInp from './components/VcodeInp.vue';
import HeadBar from './components/HeadBar.vue';

import loginApi from "@/net/loginApi"

import model from "./model"


import md5 from 'md5'

export default {
  components: { VcodeInp, HeadBar },
  data() {
    return {
      account: "",
      tel: "",
      password: "",
      pwdSeen: false,
      pswSave: false,
      tabsId: 0,
      netMsg0:'',
      netMsg1:'',
      vcode:''
    };
  },
  watch: {
    pswSave(val, oldVal) {
      localStorage.setItem('sm_psw_save', val ? '1' : '')
    },
    account(val, oldVal) {
      localStorage.setItem('sm_account', val)
    },
    password(val, oldVal) {
      localStorage.setItem('sm_password', val)
    },

    tel(val, oldVal) {
      // localStorage.setItem('sm_tel', val)
    }
  },
  created() {
    model.initFrom(this.$route.query || {})
    this.pswSave = localStorage.getItem('sm_psw_save') ? true : false
    if(this.pswSave) {
      // this.tel = localStorage.getItem('sm_tel')
      this.account = localStorage.getItem('sm_account')
      this.password = localStorage.getItem('sm_password')
    }
  },
  methods: {
    getVcode() {
      if (!checkIsTel(this.tel)) {
        this['netMsg' + this.tabsId] = "请正确填写手机号"
        return '';
      }
      return this.tel
    },
    onVcodeRcv(data) {
      if(data.success != '1') {
        this['netMsg' + this.tabsId] = data.msg
      } else {
        this['netMsg' + this.tabsId] = ''
      }
    },
    vcodeChange(newVal) {
      this.vcode = newVal
    },
    tapTab(id) {
      console.log('ttt', id)
      this.tabsId = id;
    },
    tapClose() {
      model.closeByCrx()
    },
    tapLogin() {
      if(this.tabsId == 0) {
        this.accountLogin()
      } else {
        this.telLogin()
      }
    },
    async telLogin() {
      let msg = model.checkFrom({
        tel:this.tel,
        vcode:this.vcode
      })
      if(msg) {
        this['netMsg' + this.tabsId] = msg
        return
      }

      loginApi.login({
        phone:this.tel
      }, 'loginByCode' , {code:this.vcode}).then(this.onLoginRcv)
    },
    accountLogin() {
      let msg = model.checkFrom({
        account:this.account, psw:this.password
      })
      console.log('accountLogin', msg)
      this['netMsg' + this.tabsId] = msg
      if(msg) {
        return
      }

      loginApi.login({
        phone:this.account,
        password: md5(this.password) 
      }, 'login').then(this.onLoginRcv)
    },
    onLoginRcv(data) {
      if(data.success != '1') {
        this['netMsg' + this.tabsId] = data.msg
      } else {
        console.log('登录成功')
        this['netMsg' + this.tabsId] = ''
        let uid
        if(data.obj) {
          uid = data.obj.id
        }
        model.login2Crx(uid)
      }
    },
    tapRegi() {
      this.$router.push({
        name:'register'
      })
    },
    tapMissPsw() {
      this.$router.push({
        name:'misspsw'
      })
    },
  },
};
</script>
<style lang="less" scoped>
@import "css/common";


  .box-psw {
    position: relative;
    display: inline-block;
    width: 100%;
    .btn-seen {
      position: absolute;
      right: 0;
      bottom: 14px;
      margin: auto;

      width: 16px;
      height: 16px;
      background-size: contain;
      background-image: url("imgs/eye_open@2x.png");
      &-open {
        background-image: url("imgs/eye_close@2x.png");
      }
    }
  }

  .misspsw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
  }


.btn-regi {
  color: #2f54eb;
  font-size: 14px;
  margin-top: 12px;
}
</style>
